import React from "react"
import Img from "gatsby-image"

const Horse = ({ horse }) => {
  return (
    <>
      <div className="uk-panel">
        {/*<img className="uk-align-left uk-align-left@m uk-margin-remove-adjacent" src={horse.node.image.publicURL}/>*/}
        <Img
          fixed={horse.node.image.localFile.childImageSharp.fixed}
          imgStyle={{ position: "static" }}
          className="uk-align-left uk-align-left@m uk-margin-remove-adjacent"
        />
        <h2>{horse.node.name}</h2>
        <div className="ck-content" dangerouslySetInnerHTML={{ __html: horse.node.content }} />
      </div>
    </>
  )
}

export default Horse