import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout/layout"

import Horses from "../components/Horses/Horses"
import ImageTop from "../components/ImageTop/ImageTop"

const HorsesPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <ImageTop
        image={ data.strapiHorsesData.image }
        sizes='100vw'
        title={data.strapiHorsesData.title}
      />

      <div className="uk-container uk-container-small">
        <Horses horses={data.allStrapiHorses.edges} />
      </div>
    </Layout>
  )
}

const query = graphql`
    query {
        strapiHomepage {
            seo {
                metaTitle
                metaDescription
            }
        }
        strapiHorsesData {
            title
            image{
                localFile{
                    childImageSharp {
                        fixed(width: 1800, height: 1200) {
                            src
                        }
                    }
                }
            }
        }
        allStrapiHorses(sort:{fields: position, order: ASC}) {
            edges {
                node {
                    strapiId
                    name
                    content
                    image {
                        localFile{
                            childImageSharp {
                                fixed(width: 220, height: 290) {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default HorsesPage