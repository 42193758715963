import React from "react"
import Horse from "../Horse/Horse"

const Horses = ({ horses }) => {
  return (
    <div className="uk-grid" data-ukgrid>
      {horses.map((horse, i) => {
        return (
          <Horse horse={horse} />
        )
      })}
    </div>
  )
}

export default Horses